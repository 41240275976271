<template>
  <Form
    class="form"
    :validation-schema="schemaArray[currentStep]"
    :initial-values="initForm"
    keep-values
    v-slot="{ values, setValues }"
    @submit="nextStep"
    @invalid-submit="onInvalidSubmit"
  >
    <!-- 步驟條 -->
    <div class="formWizard-steps">
      <!-- processbar -->
      <div
        class="formWizard-progressbar-wrapper"
        :style="{
          width: `${100 - 100 / formStepsName.length}%`,
          left: `${100 / formStepsName.length / 2}%`,
        }"
      >
        <div
          class="formWizard-progressbar"
          :style="{ width: `${100 * (currentStep / (formStepsName.length - 1))}%` }"
        ></div>
      </div>
      <!-- step -->
      <div
        class="formWizard-step"
        :class="{ 'is-current': index === currentStep, 'is-active': index < currentStep }"
        v-for="(step, index) in formStepsName"
        :key="index"
      >
        <i class="formWizard-step-icon">
          <i class="icon icon-check" v-if="index < currentStep"></i>
          <template v-else>{{ index + 1 }}</template>
        </i>
        <div class="formWizard-step-title">{{ step }}</div>
      </div>
    </div>

    <!-- 內容 -->
    <div class="formWizard-content">
      <template v-if="!isFinishStep">
        <div class="h4">{{ formStepsName[currentStep] }}</div>
        <slot
          :current-form-data="values"
          :set-values="(newValue) => {setValues(Object.assign({},values, newValue))}"
        />
      </template>
      <!-- 完成頁面 -->
      <template v-else>
        <slot :current-form-data="values" />
      </template>
    </div>

    <!-- 步驟按鈕 -->
    <div class="formWizard-footer">
      <button
        type="button"
        v-if="!isFirstStep && !isFinishStep"
        class="btn btn-secondary"
        @click="currentStep--"
      >
        上一步
      </button>
      <button
        type="button"
        v-if="hasSaveDraft && !isFirstStep && !isFinishStep"
        class="btn btn-secondary"
        @click="emitSaveDraft(values)"
      >
        儲存為草稿
      </button>
      <button v-if="!isFinishStep" class="btn btn-primary">下一步</button>
      <button type="button" v-if="isFinishStep" class="btn btn-primary" @click="emitFinish">
        {{ finishText }}
      </button>
    </div>
  </Form>
</template>
<script>
import { computed } from 'vue';
import _ from 'lodash';
import { Form } from 'vee-validate';
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useLoadingScreen } from '@/store/index';
import { useFormStore } from '@/store/form';
import { useUserData } from '@/store/user';

export default {
  components: {
    Form,
  },
  props: {
    initForm: {
      type: Object,
      default: null,
    },
    initStep: {
      type: Number,
      default: 0,
    },
    schemaArray: {
      type: Array,
      required: true,
    },
    formStepsName: {
      type: Array,
      required: true,
    },
    hasSaveDraft: {
      type: Boolean,
      default: false,
    },
    finishText: {
      type: String,
      default: '完成',
    },
    submitStepIndex: {
      type: Number, // emitSubmit after this step
      required: true,
    },
  },
  data() {
    return {
      currentStep: this.initStep,
      isFinish: false,
    };
  },
  provide() {
    return {
      currentStep: computed(() => this.currentStep),
    };
  },
  methods: {
    async nextStep(result) {
      if (this.isSubmitStep) {
        this.emitSubmit(result);
      } else {
        this.$emit('nextStep');
        window.scrollTo({
          behavior: 'smooth',
          top: 0,
        });
        this.currentStep += 1;
        this.formData = _.assign({}, this.formData, _.cloneDeep(result));
        if (this.isLogin) {
          await this.fetchRentRateTable();
        }
      }
    },
    onInvalidSubmit({ errors }) {
      const firstInvalidFieldName = Object.keys(errors)[0];
      const $el = document.querySelector(`[name="${firstInvalidFieldName}"]`);

      // fieldArray
      if (!$el) alert(errors[firstInvalidFieldName]);

      this.$nextTick(() => {
        $el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      });
    },
    emitSubmit(formData) {
      this.$emit('submitForm', formData, () => {
        this.currentStep += 1;
      });
    },
    emitSaveDraft(result) {
      this.formStep = this.currentStep;
      this.formData = _.assign({}, this.formData, _.cloneDeep(result));
      this.$emit('saveDraft');
    },
    emitFinish() {
      this.isFinish = true;
      this.$emit('finish');
    },
    ...mapActions(useLoadingScreen, ['setLoading']),
    ...mapActions(useFormStore, ['fetchRentRateTable']),
  },
  computed: {
    isFirstStep() {
      return this.currentStep === 0;
    },
    isFinishStep() {
      return this.currentStep >= this.formStepsName.length - 1;
    },
    isSubmitStep() {
      return this.currentStep === this.submitStepIndex;
    },
    ...mapWritableState(useFormStore, ['formStep', 'formData']),
    ...mapState(useUserData, ['isLogin']),
  },
};
</script>
